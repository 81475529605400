.privateFooter {
    .companyWebsiteLink {
        color: #007bff;
        text-decoration: none;

        &:hover {
            color: #0056b3;
            text-decoration: underline;
        }
    }
}
