@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?jxw6jz');
  src:  url('fonts/icomoon.eot?jxw6jz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?jxw6jz') format('truetype'),
    url('fonts/icomoon.woff?jxw6jz') format('woff'),
    url('fonts/icomoon.svg?jxw6jz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sum:before {
  content: "\e943";
}
.icon-database:before {
  content: "\e941";
}
.icon-briefcase:before {
  content: "\e93e";
}
.icon-hash:before {
  content: "\e93f";
}
.icon-user-check:before {
  content: "\e940";
}
.icon-user-minus:before {
  content: "\e93c";
}
.icon-user-plus:before {
  content: "\e93d";
}
.icon-shield-undefined:before {
  content: "\e935";
}
.icon-average:before {
  content: "\e92f";
}
.icon-min:before {
  content: "\e930";
}
.icon-max:before {
  content: "\e931";
}
.icon-book-check:before {
  content: "\e924";
}
.icon-sort_by_alpha:before {
  content: "\e911";
}
.icon-compare_arrows:before {
  content: "\e912";
}
.icon-book:before {
  content: "\e907";
}
.icon-book-add:before {
  content: "\e91b";
}
.icon-calendar:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e91e";
}
.icon-chevron-down:before {
  content: "\e909";
}
.icon-chevron-left:before {
  content: "\e90a";
}
.icon-chevron-right:before {
  content: "\e90b";
}
.icon-chevron-up:before {
  content: "\e90c";
}
.icon-clock:before {
  content: "\e90d";
}
.icon-download:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e921";
}
.icon-edit-2:before {
  content: "\e91f";
}
.icon-film:before {
  content: "\e900";
}
.icon-film-add:before {
  content: "\e91c";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-folder:before {
  content: "\e913";
}
.icon-folder-add:before {
  content: "\e91d";
}
.icon-help-circle:before {
  content: "\e901";
}
.icon-info:before {
  content: "\e923";
}
.icon-lock:before {
  content: "\e919";
}
.icon-log-out:before {
  content: "\e902";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-menu:before {
  content: "\e905";
}
.icon-minus-circle:before {
  content: "\e90f";
}
.icon-move:before {
  content: "\e917";
}
.icon-paperclip:before {
  content: "\e922";
}
.icon-play-circle:before {
  content: "\e918";
}
.icon-plus-circle:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e903";
}
.icon-trash-2:before {
  content: "\e915";
}
.icon-upload:before {
  content: "\e916";
}
.icon-user:before {
  content: "\e904";
}
.icon-x:before {
  content: "\e906";
}
.icon-arrow-down-right:before {
  content: "\e927";
}
.icon-arrow-right:before {
  content: "\e92e";
}
.icon-arrow-up-right:before {
  content: "\e928";
}
.icon-bar-chart-2:before {
  content: "\e92d";
}
.icon-globe:before {
  content: "\e925";
}
.icon-sidebar:before {
  content: "\e926";
}
.icon-sliders:before {
  content: "\e932";
}
.icon-thumbs-down:before {
  content: "\e929";
}
.icon-thumbs-up:before {
  content: "\e92c";
}
.icon-trending-down:before {
  content: "\e92b";
}
.icon-trending-up:before {
  content: "\e92a";
}
.icon-bell:before {
  content: "\e936";
}
.icon-settings:before {
  content: "\e937";
}
.icon-shield-off:before {
  content: "\e934";
}
.icon-shield:before {
  content: "\e933";
}
.icon-alert-octagon:before {
  content: "\e93a";
}
.icon-alert-triangle:before {
  content: "\e93b";
}
.icon-copy:before {
  content: "\e939";
}
.icon-more-horizontal:before {
  content: "\e920";
}
.icon-more-vertical:before {
  content: "\e938";
}
.icon-rotate-ccw:before {
  content: "\e942";
}
