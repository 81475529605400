.spinnerRootContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1500;
  top: 0;

  &.left-0 {
    left: 0;
  }

  .spinner-border {
    top: 50% !important;
    left: 52% !important;

    @media (max-width: 576px) {
      top: 44% !important;
      left: 51% !important;
    }
  }

  @media (max-width: 576px) {
    margin-left: 0px;
  }
}
