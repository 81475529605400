.genresContainer {
    font-size: 85%;
    width: 100%;
    margin-left: 4px !important;

    .form-check-label {
      margin-bottom: .5rem;
    }

    .form-check-input {
      margin-top: 0px;
    }
  }