  .advancedSearchDateContainer {
    .rdtPicker {
      border-radius: 4px;
      right: 0px;
      bottom: calc(1.5em + 0.5rem + 1px);
      color: #212529;

      .rdtActive {
        border-radius: 4px;
        background-color: #007bff;
      }

      th {
        border-bottom: none;
      }
    }

    .advancedSearchDatePickerContainer {
      padding: 0 !important;

      .advancedSearchDatePickerOpenButton {
        padding: 2px 7px 1px 7px;
        border: 0;
        color: #495057;
        background-color: #e9ecef;
      }
    }
  }