.homePageCenterSection {
  .searchFilmsForm {
    padding-left: 24px;
    padding-right: 24px;

    .searchFilmsInput {
      padding: .5rem 1rem !important;
      font-size: 1.25rem;
      outline: none;
      box-shadow: none;
      border-bottom: 0px;
      margin-top: 0.5px;
      margin-bottom: 0.5px;
      height: calc(1.5em + 1rem + 1px);

      &:focus {
        border-bottom: 0px;
        border-top: 0px;
      }
    }

    .searchFilmsSearchIconButton {
      font-size: 1.25rem;
      margin-right: 15px;
    }

    .searchFilmsConfigDropDownButton.dropdown-toggle {
      font-size: 1.25rem;
      padding-left: 0px;
      padding-top: 0.7rem;
    }

    .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-menu {
      top: 48px !important;
    }

    .searchFilmsClearSearchInputButton {
      font-size: 1.25rem;
      margin-top: 0.7rem;
    }

    .searchFilmsInputGroup {
      border-radius: 5px;
    }
  }
}

.searchFilmsInputGroup {
    background-color: white;
    border-radius: 3px;
  }
  
  .searchFilmsInputGroup .searchFilmsInput {
    text-align: center;
    border-left: 0px;
    border-right: 0px;
  }
  
  .searchFilmsInputGroup .searchFilmsClearSearchInputButton {
    vertical-align: initial;
    box-shadow: none !important;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  
  .searchFilmsInputGroup .searchFilmsClearSearchInputButton:before {
    border: none;
    vertical-align: center;
  }
  
  .searchFilmsInputGroup .searchFilmsSearchIconButton {
    vertical-align: initial;
    margin-left: 15px;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .searchFilmsInputGroup .searchFilmsSearchIconButton:before {
    border: none;
    vertical-align: center;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown {
    position: static;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-menu {
    top: 38px !important;
    transform: none !important;
    width: 100% !important;
    z-index: 998;
    right: 0;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-item {
    font-size: 83%;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-item.icon-folder:before {
    margin-right: 5px;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-item.icon-film:before {
    margin-right: 5px;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDown .dropdown-header {
    font-size: 82%;
    color: #212529;
    font-weight: bold;
  }
  
  .searchFilmsInputGroup .searchFilmsConfigDropDownButton.dropdown-toggle {
    border: none !important;
    background: white !important;
    color: #6C757D !important;
    outline: none !important;
    box-shadow: none !important;
    padding-right: 15px;
  }

  .privateHeader {
    .headerLinkAndSearchFieldWrapper {
      width: 86%;
    }

    .searchFilmsForm {
      min-width: 60%;
      padding-left: 0px;
      padding-right: 0px;

      .form-group {
        margin-bottom: 0px;
      }
    }

    .searchFilmsInputGroup {
      background: #F8F9FA;
  
      #search {
        background: #F8F9FA;
        border: none;
        text-align: left;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
  
      .searchFilmsConfigDropDownButton {
        background: #F8F9FA !important;
        margin-top: 3px;
      }
    }

    .advancedSearchFieldsCollapse {
      .row {
        margin-right: 0px;
      }

      label {
        width: 100%;
      }

      .advancedSearchSubmitResetButtonsContainer {
        width: 100%;
      }

      .advancedSearchFieldsContainer {
        .form-group {
          margin-bottom: 1rem;
          padding-right: 0px;
        }
      }
    }
  }

  .searchFilmsInputGroup .searchFilmsConfigDropDown .advancedSearchFilmsConfigDropDownButton {
    font-weight: bold;
    font-size: 82%;

    span {
      margin-right: 5px;
    }

    &:focus {
      background-color: white;
    }
  }

  .advancedSearchFieldsCollapse {
    .advancedSearchFieldsContainer {
      font-size: 82%;

      fieldset {
        width: 100%;
      }
    }
  }

  .advancedSearchSubmitResetButtonsContainer {
    button {
      background: white;
    }

    .btn-primary {
      background-color: #007bff;
      border-color: #007bff;

      &:hover {
        background-color: #0069d9;
        border-color: #0069d9;
      }

      &:focus {
        background-color: #007bff;
      }
    }

    .btn-secondary {
      margin-right: 5px;

      &:hover {
        background-color: #007bff;
        border-color: #007bff;
      }

      &:focus {
        background-color: white;
        color: #007bff;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
      }

      &:active {
        color: white !important;
        background-color: #0062cc !important;
        border-color: #0062cc !important;
      }
    }
  }

  .fakeSafariSubmitInput {
    visibility: hidden !important;
    width: 1px;
    height: 1px;
    position: absolute;
  }