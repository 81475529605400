.privateHeader {
    .activeIconLink {
        color: #191919 !important;
    }

    .activeProfileIcon {
        background-color: #dae0e5 !important;
        color: #000 !important;
    }

    .rounded-circle.icon-user {
        &:focus {
            background-color: #dae0e5 !important;
            color: #000 !important;
        }
    }

    .hide {
        display: none !important;
    }
}
