.notificationsPopup {
    .dropdown-menu {
        font-size: 80%;
        padding: 0px;

        .dropdown-item {
            padding: 0.75rem 1rem;
    
            &:hover {
              cursor: pointer !important;
            }
        }

        .dropdown-item.icon-info:active {
            background-color: #dc3545 !important;
        }

        .dropdown-item.icon-alert-triangle:active {
            background-color: #ffc107 !important;
        }
    }

    .dropdown-menu.notifications .singleNotificationContainer:not(:first-child) {
        .dropdown-item {
            border-top: 1px solid rgba(0, 0, 0, .1);
        }
    }

    .dropdown-menu.notifications .singleNotificationContainer:first-child {
        .dropdown-item {
            border-top-left-radius: .25rem;
            border-top-right-radius: .25rem;
        }
    }

    .notificationsPopupToggle {
        border: none !important;
        background: transparent !important;
        color: #8a8a8a !important;
        outline: none !important;
        box-shadow: none !important;
        padding: 0px 8px 4px 8px !important;

        &:hover {
            color: #000 !important;
        }

        &:focus {
            outline: 0 !important;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5) !important;
        }
    }

    .icon-info, .icon-alert-triangle {
        &::before {
          display: inline-block;
          vertical-align: top;
          margin-top: 8px;
          font-size: 130%;
        }
    }

    .icon-info {
        &::before {
          color: #dc3545;
        }
    }

    .icon-info.active {
        background-color: #dc3545;
        color: #fff;

        &::before {
            color: #fff;
        }

        .text-secondary {
            color: #fff !important;
        }
    }

    .icon-alert-triangle {
        &::before {
          color: #ffc107;
        }
    }

    .icon-alert-triangle.active {
        background-color: #ffc107;
        color: #fff;

        &::before {
            color: #fff;
        }

        .text-secondary {
            color: #fff !important;
        }
    }

    .unread-notifications-circle {
        &::after {
          content: '\2022';
          position: absolute;
          left: 20%;
          width: 100%;
          font-size: 152%;
          line-height: 100%;
          color: var(--pink);
          top: 19%;
        }
    }

    .notificationSummaryContainer {
        display: inline-block;
        margin-left: 7px;

        .notificationSummaryTitleContainer {
            margin-bottom: -5px;
        }
    }

    .notificationDetailsContainer {
        padding: 0.75rem;
        color: var(--secondary);

        .btn-group {
            display: flex;
            justify-content: space-between;
            padding-top: 0.75rem;

            a {
                text-decoration: none;
            }

            .notification-dismiss-btn {
                color: var(--danger);
                background-color: transparent;
                border: none;
            }
        }
    }
}